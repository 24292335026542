.testimonialspage-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.testimonialspage-head {
  margin-top: 2rem;
  color: #333;
}
