.contact-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px 0;
  width: 100%;
  background-color: #a17bec;
}

.contact-container {
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1200px;
}

.contact-heading h2 {
  text-align: center;
  font-size: 36px;
  margin-bottom: 40px;
  color: #fff;
}

.contact-content {
  display: flex;
  justify-content: center;
}

.contact-form {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  margin: 10px;
  overflow: hidden;
  max-width: 600px;
}

.contact-form-top {
  background-image: url("./girlimage.png");
  background-size: cover;
  background-position: center;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.contact-form-top:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.contact-form-con {
  margin: 20px;
  text-align: center;
  color: #fff;
  position: relative;
  z-index: 2;
}

.contact-send-message {
  font-size: 30px;
  font-weight: 800;
  margin-bottom: 10px;
}

.contact-form-bottom {
  margin: 30px;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  font-weight: 600;
  color: #333;
  margin-bottom: 5px;
}

input,
textarea {
  width: 100%;
  padding: 12px;
  border-radius: 5px;
  border: 1px solid #ccc;
  transition: border-color 0.3s ease;
  font-size: 16px;
}

input:focus,
textarea:focus {
  border-color: #8a2be2;
}

.btn-submit {
  display: block;
  width: 100%;
  padding: 12px 30px;
  border-radius: 5px;
  background-color: #8a2be2;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  border: none;
  cursor: pointer;
  border-radius: 25px;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.btn-submit:hover {
  background-color: #6a1cab;
}
