.modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.open-button {
  padding: 0.75rem 1rem;
  font-size: 0.875rem;
  font-weight: 600;
  border: none;
  border-radius: 0.375rem;
  background-color: #3b82f6;
  color: #fff;
  cursor: pointer;
}

.modal-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  opacity: 0;
  overflow-x: hidden;
  transition: opacity 0.5s;
}

.modal-overlay.open {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
}

.modal-content {
  max-width: 800px;
  max-height: 600px;
  margin: 1rem;
  height: auto;
  border: 1px solid #d1d5db;
  border-radius: 0.75rem;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.modal-header {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
}

.modal-title {
  font-size: 1.5em;
  font-weight: 700;
  color: #4b5563;
}

.close-button {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  padding: 0.5rem;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.close-icon {
  width: 1rem;
  height: 1rem;
  stroke: currentColor;
}

.modal-body {
  padding: 1rem;
}

.avatar-container {
  position: relative;
  display: inline-block;
  align-items: center;
  width: 100%;
  height: auto;
}

.avatar-container img {
  width: 100%;
  height: auto;
}

.close-icon {
  fill: grey;
}
