.header-nav {
  width: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.9);
  border-color: #e2e8f0;
  padding: 0.625rem 0rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  z-index: 1000;
}

.header-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
}

.site-logo-anchor {
  display: flex;
  align-items: center;
  text-decoration: none;
  margin: 0px 10px;
}

.logo {
  margin-right: 0.75rem;
  height: 2.5rem;
}

.site-name {
  align-self: center;
  font-size: 1.75rem;
  font-weight: 600;
  white-space: nowrap;
  color: #333;
}

.header-container2 {
  display: flex;
  align-items: center;
}

.header-nav-links-container {
  display: block;
}

.header-link {
  color: #4b5563;
  background-color: transparent;
  transition:
    background-color 0.2s ease,
    border-color 0.2s ease,
    color 0.2s ease;
  font-weight: 500;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  margin-right: 0.5rem;
  outline: none;
  text-decoration: none;
}

.header-link:hover {
  background-color: #f9fafb;
  color: #111827;
}

.header-link:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(156, 163, 175, 0.5);
}

.header-whatsapp {
  color: #fff;
  background-color: #128c7e;
  transition:
    background-color 0.2s ease,
    border-color 0.2s ease,
    color 0.2s ease;
  font-weight: 500;
  border-radius: 1rem;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  margin-right: 0.5rem;
  outline: none;
  text-decoration: none;
}

.header-whatsapp:hover {
  background-color: #25d366;
}

.header-whatsapp:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(37, 211, 102);
}

.menu-button {
  all: unset;
  display: none;
  align-items: center;
  padding: 0.5rem;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  font-size: 0.9rem;
  color: #4b5563;
  border-radius: 0.375rem;
  outline: none;
}

@media only screen and (max-width: 950px) {
  .header-nav-links-container {
    display: none;
  }
  .menu-button {
    display: block;
  }
}

.menu-button:hover {
  background-color: #f9fafb;
}

.menu-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(209, 213, 219, 0.5);
}

.svg-btn {
  width: 1.5rem;
  height: 1.5rem;
}

.hidden {
  display: none;
}

.nav-items-container {
  display: block;
  position: relative;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.nav-bar-ul {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  font-weight: 500;
  list-style-type: none;
}

.nav-bar-ul {
  list-style-type: none;
  padding: 0;
}

.nav-bar-ul li {
  margin-bottom: 0.5rem;
}

.nav-bar-ul li a {
  display: block;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  text-decoration: none;
  color: #333333;
  display: block;
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;
  margin: 5px 10px;
  transition: background-color 0.3s ease;
}

.nav-bar-ul li a:hover {
  color: #ffffff;
  background-color: black;
}
