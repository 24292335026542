.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.about-content {
  max-width: 800px;
  margin: 20px;
  margin-top: 80px;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about-content h1 {
  color: #6a1cab;
  margin-bottom: 20px;
}

.about-content p {
  color: #264653;
  line-height: 1.6;
}
