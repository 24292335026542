.hero {
  width: 100%;
  height: 100vh;
  background: url("bg.jpg");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* @media only screen and (max-width: 600px) {
    .hero {
          background-position: 75% center;
    }
} */

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

.content {
  position: relative;
  z-index: 1;
  text-align: center;
  color: #fff;
}

.content h1 {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.content p {
  font-size: 1.5rem;
  margin: 15px;
  margin-bottom: 30px;
}

.hero-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 20px;
}

.button {
  background-color: #fff;
  color: #333;
  padding: 10px 20px;
  border-radius: 40px;
  border: 2px solid transparent;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: bold;
  transition: all 0.3s ease;
  position: relative;
}

.button:hover {
  transform: translateY(-5px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
