.blog-post {
  display: flex;
  cursor: pointer;
  height: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  margin: 20px;
  border: 0.5px solid gray;
  overflow: hidden;
  height: 400px;
  overflow: hidden;
}

.blog-image {
  flex: 1;
  height: 100%;
}

.blog-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 450px) {
  .blog-post {
    flex-direction: column;
    height: auto;
  }
  .blog-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.blog-post-content {
  flex: 1;
  padding: 1.25rem;
  height: 100%;
  border-radius: 1rem;
  font-size: 1.25rem;
  line-height: 1.75;
  gap: 0.5rem;
}

.blog-post:hover {
  background-color: rgba(156, 163, 175, 0.2);
}

.blog-post:active {
  background-color: rgba(156, 163, 175, 0.4);
}

.blog-head-container {
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  width: 100%;
}

.blog-title {
  font-weight: 600;
  flex: 1;
  font-size: 1.5rem;
  line-height: 1;
  margin-bottom: 1rem;
}

@media (min-width: 1024px) {
  .blog-title {
    font-size: 1.875rem;
  }
}

.blog-title:hover {
  text-decoration: underline;
}

.blog-date {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 0.5rem;
  align-items: center;
  font-weight: 400;
  font-size: 1rem;
  color: #6b7280;
}

.blog-para-container {
  font-size: 1rem;
  color: #4b5563;
}

.blog-para {
  font-size: 1rem;
  text-decoration: none;
  font-weight: 400;
  color: #4b5563;
}

.blog-read-more {
  color: #4f46e5;
  font-size: 1rem;
}

.blog-read-more:hover {
  color: #3490dc;
  text-decoration: underline;
}

.blog-image {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
}
