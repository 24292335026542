.floating-instagram {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 6.5rem;
  right: 2rem;
  cursor: pointer;
  background-color: transparent;
  border-radius: 50%;
  -webkit-user-select: none;
  user-select: none;
  z-index: 9998;
}

.floating-instagram a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  color: #fff;
  font-size: 2rem;
  text-decoration: none;
  transition: 0.3s;
}

.floating-instagram img {
  width: 75%;
  height: 75%;
}

.floating-instagram:hover {
  transform: scale(1.1);
}
